import { useMemo } from 'react'
import { routeData } from 'links'
import { usePathname, useRouter } from 'router'


const useRouteData = () => {
  const pathname = usePathname()
  const { matcher } = useRouter()

  return useMemo<{ title?: string, tag?: UserModel.ModuleTag }>(() => {
    const match = Object.keys(routeData).find((key) => {
      return matcher(key.replace(/\?.*/, ''), pathname)
    })

    if (match) {
      return routeData[match]
    }

    const rootPath = '/' + pathname.replace(/^\//, '').replace(/\/.*/, '')
    const matchByRootPath = Object.keys(routeData).find((key) => matcher(key.replace(/\?.*/, ''), rootPath))

    if (matchByRootPath) {
      return routeData[matchByRootPath]
    }

    return {}
  }, [ pathname, matcher ])
}


export default useRouteData